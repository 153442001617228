<template>
  <v-card>
    <v-card-text>
      <v-data-table
        locale="fr-FR"
        :page="options.page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="items"
        :sort-by="'updated'"
        :sort-desc="true"
        :options.sync="options"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        :items-per-page="options.itemsPerPage"
      >
        <template v-slot:item.lcp="{ item }">
          <span :class="getColorByRange('LCP', item.lcp)">{{
            formatCwvMetric(item.lcp, "LCP")
          }}</span>
        </template>
        <template v-slot:item.fcp="{ item }">
          <span :class="getColorByRange('FCP', item.fcp)">{{
            formatCwvMetric(item.fcp, "FCP")
          }}</span>
        </template>
        <template v-slot:item.cls="{ item }">
          <span :class="getColorByRange('CLS', item.cls)">{{
            formatCwvMetric(item.cls, "CLS")
          }}</span>
        </template>
        <template v-slot:item.ttfb="{ item }">
          <span :class="getColorByRange('TTFB', item.ttfb)">{{
            formatCwvMetric(item.ttfb, "TTFB")
          }}</span>
        </template>
        <template v-slot:item.inp="{ item }">
          <span :class="getColorByRange('INP', item.inp)">{{
            formatCwvMetric(item.inp, "INP")
          }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          {{ item.rawDate }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<style scoped></style>
<script>
import { formatCwvMetric } from "@/components/web-perf/cwv/cwv-sites-performance/utils/utils";
import useAxios from "@/hooks/useAxios";
import { mdiFileDocumentOutline } from "@mdi/js";
import debounce from "lodash/debounce";

export default {
  name: "CwvRawDataTable",
  props: {},
  setup() {
    const { axiosGet } = useAxios();
    return {
      axiosGet,
    };
  },
  async created() {
    this.queryParams = this.$route.query;
    await this.getData();
  },
  data() {
    return {
      queryParams: {},
      search: "",
      numberOfPages: 0,
      items: [],
      deviceMapping: {
        15: "Mobile",
        16: "Desktop",
        17: "Tablette",
      },
      deviceIds: {
        Mobile: 15,
        Desktop: 16,
        Tablette: 17,
      },
      loading: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
      },
      icons: {
        mdiFileDocumentOutline,
      },
      ranges: {
        LCP: [2500, 4000],
        INP: [200, 500],
        CLS: [0.1, 0.25],
        FCP: [1800, 3000],
        TTFB: [800, 1800],
      },
      headers: [
        {
          text: "Heure de la visite",
          value: "datetime",
          align: "start",
          visible: true,
        },
        {
          text: "Dernière URL",
          value: "lastUrl",
          visible: true,
        },
        {
          text: "Page type",
          value: "pageType",
          visible: true,
        },
        {
          text: "Device",
          value: "device",
          visible: true,
        },
        {
          text: "LCP",
          value: "lcp",
          visible: true,
          width: "100px",
        },
        {
          text: "FCP",
          value: "fcp",
          visible: true,
          width: "100px",
        },
        {
          text: "CLS",
          value: "cls",
          visible: true,
          width: "100px",
        },
        {
          text: "TTFB",
          value: "ttfb",
          visible: true,
          width: "100px",
        },
        {
          text: "INP",
          value: "inp",
          visible: true,
          width: "100px",
        },
        {
          text: "",
          value: "actions",
          visible: true,
        },
      ],
    };
  },
  methods: {
    formatCwvMetric,
    getColorByRange(metric, value) {
      const range = this.ranges[metric];
      if (value <= range[0]) {
        return "success--text";
      } else if (value <= range[1]) {
        return "warning--text";
      } else {
        return "error--text";
      }
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      const params = {};
      if (this.date && this.date.length > 0) {
        const [day, month, year] = this.date.split("/");
        params.date = `${year}-${month}-${day}`;
      }
      if (this.site && this.site.length > 0) {
        params.site = this.site;
      }
      if (this.pageType && this.pageType.length > 0) {
        params["page_type"] = this.pageType;
      }
      if (this.device && this.device.length > 0) {
        params.device = this.deviceIds[this.device];
      }
      this.loading = true;

      const { data } = await this.axiosGet("/web-perf/cwv-raw-data", params);
      this.items = data.items;

      for (const item of this.items) {
        const date = new Date(item.time).toLocaleDateString("fr-FR");
        const time = new Date(item.time).toLocaleTimeString("fr-FR");
        item.datetime = `${date} ${time}`;
        item.device = this.deviceMapping[item.device];
      }

      this.loading = false;
    },
  },
  computed: {
    site() {
      return this.queryParams.site;
    },
    pageType() {
      return this.queryParams["page-type"];
    },
    device() {
      return this.queryParams.device;
    },
    date() {
      return this.queryParams.date;
    },
  },
};
</script>
<style lang="scss" scoped></style>

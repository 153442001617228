<template>
  <div>
    <p class="text-2xl mb-6">Les données CWV brutes</p>
    <cwv-raw-data-table />
  </div>
</template>

<script>
import CwvRawDataTable from "@/components/web-perf/cwv/cwv-raw-data/tables/CwvRawDataTable.vue";

export default {
  name: "CwvRawData",
  components: {
    CwvRawDataTable,
  },
  created() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "largeWidth");
  },
  beforeDestroy() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "boxed");
  },
};
</script>
<style scoped></style>
